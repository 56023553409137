// cookie操作
import { useCookies } from "react-cookie";

export function SetCookie ( location ) {
  
  // cookie設定
  const [cookiesB, setCookieB] = useCookies(['pid']);
  const [cookiesC, setCookieC] = useCookies(['lid']);

  if( location !== undefined ) { // locationがとれていないときは処理なし（初回レンダリング時）
    const postUri = location.href;
    let splitUri;
    if ( postUri !== undefined ) {
      splitUri = postUri.split( '/' );
    }

    // IB情報有無判別
    const setCookieArray = ['lid', 'pid'] 
    let parameter;
    (postUri !== undefined ) ? parameter = splitUri.slice(-1)[0] : parameter = '';
    let setResultA, setResultB, setResultC;
    setResultA = parameter.indexOf( '?' );
    setResultB = parameter.indexOf( setCookieArray[0] );
    setResultC = parameter.indexOf( setCookieArray[1] );
    if ( setResultA === -1 && setResultB === -1 && setResultC === -1 ) { // 含む場合
      parameter = '';
    }

    // 取得情報１
    let firstItem, endItem, addNum;
    firstItem = 'lid';
    endItem = '&'; // 末尾の場合はnull
    addNum = '';
    let lidNum = makeTabContens(parameter, firstItem, endItem, addNum)
    
    // 取得情報２
    firstItem = 'pid';
    endItem = 'null'; // 末尾の場合はnull
    addNum = '';
    let pidNum = makeTabContens(parameter, firstItem, endItem, addNum)

    // クッキー設定
    const expires = new Date(Date.now() + 30 * 24 * 60 * 60 * 1000); // 有効期限を30日に設定
    const path = '/';
    // const [cookiesA, setCookieA, removeCookieA] = useCookies(['language']); // 言語cookieは一旦不要
    
    // 言語cookieは一旦不要
    // removeCookieA("language"); 
    // setCookieA("language", setLang);
    if ( parameter !== '' ) {
      setCookieB("pid", pidNum, { expires, path }); // 上書き
      setCookieC("lid", lidNum, { expires, path }); // 上書き
    }
    
    // 文字列取得用関数
    function makeTabContens( data, beforeItem, afterItem, addNum){
      let index  = data.indexOf(beforeItem) + addNum;
      let string = data.substring(index);
      (afterItem === 'null') ? index = 99 : index = string.indexOf(afterItem); // &前か後か
      string = string.substring(beforeItem.length + 1, index);
      return string
    };
  }
  return {pidID:cookiesB.pid, lidID:cookiesC.lid}
}