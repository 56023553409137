import React, { useState } from 'react';
import parse from "html-react-parser"

// 言語用Jsonファイル
import langEn from "@content/locales/en/blog-archive.json";
import langJa from "@content/locales/ja/blog-archive.json";

export function ChangeContents ( content, setLang ) {

  let postLang;
  setLang === 'en'? postLang = langEn: postLang = langJa;

	const parseContents = parse(content).filter(value => typeof value !== 'string'); // "\n"(文字列)を削除するために文字列でないもののみをフィルター
	
  // 続きを見るボタンのindexを取得
	let setArray = [];
	parseContents.forEach((result, index) => {
		if (result.props?.children !== null) {
			if(result.props?.children[1]?.props?.id === 'readMoreSection'){
				setArray.push({data: result.props?.children[1]?.props?.id, num: index})
			}
		}
	})

  // 表示切替用hook
  const [isVisible, setIsVisible] = useState(false);
  const toggleVisibility = () => {
    setIsVisible(!isVisible);
    if (isVisible) {
      const targetElement = document.getElementById('targetElement');
      const yOffset = 300; // 上方向に追加スクロール
      const y = targetElement.getBoundingClientRect().top + window.pageYOffset - yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };

  if(setArray.length !== 0) { // 記事内に「readMoreSection」あり
    const ascArray = [...setArray]; // コピーして昇順配列を作成
    const descArray = [...setArray].reverse(); // コピーして降順配列を作成
    
    // 目印用buttonの削除（降順で消していく）
    for (let i = 0; i < setArray.length; i++){
      parseContents.splice(descArray[i].num, 1); ;
    }
    // 配列を2分割
    const openArray = parseContents.slice(0, ascArray[0].num)
    const closeArray = parseContents.slice(ascArray[0].num)

    // 出力用htmlの設定
    const postHtml = <>
    
      {/* 初期表示 */}
      {openArray}
      {/* 非表示ボタンからの戻り位置 */}
      <div id="targetElement"></div>

      {/* 追加表示を薄く表示 */}
      {!isVisible &&
        <div className='mb-[50px] h-[300px] overflow-hidden opacity-50'>
          {closeArray}
        </div>
      }

      {/* 追加表示ボタン */}
      {!isVisible && 
        <div className='flex justify-center'>
          <button className='bg-[#F7F7F7] min-w-[250px] p-2.5 rounded-[3px]' onClick={toggleVisibility}>{postLang["readmore-display-txt"]}</button>
        </div>
      }
      
      {/* 追加表示 */}
      {isVisible && 
        <div className="">
          {closeArray}

          {/* 追加非表示ボタン */} 
          <div className='flex justify-center mt-[50px]'>
            <button className='bg-[#F7F7F7] min-w-[250px] p-2.5 rounded-[3px]' onClick={toggleVisibility}>{postLang["readmore-hide-txt"]}</button>
          </div>
        </div>
      }
    </>
    return postHtml

  } else { // 記事内に「readMoreSection」なし
    return parseContents

  }
};